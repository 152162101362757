<div *ngIf="showAlert" class="overlay" (click)="close()">
  <div class="custom-alert-box" (click)="$event.stopPropagation()">
    <div class="alert-header">
      <span class="alert-title">Hinweis</span>
      <button class="close-button" (click)="close()">&times;</button>
    </div>
    <div class="alert-body">
      <p>{{ message }}</p>
    </div>
    <div class="alert-footer">
      <button class="confirm-button" (click)="close()">OK</button>
    </div>
  </div>
</div>

<h1 class="text-primary" appTranslationModal [translate]="'Dokumente und wirtschaftlich Berechtigte'"></h1>

<form class="col-xl-10 card-container">
  <div class="row">
    <div class="col-12">
      <div class="row section-container">
        <h3 appTranslationModal [translate]="'STAMMDATEN.DOKUMENTE_HEADER'"></h3>
        <p appTranslationModal [translate]="'STAMMDATEN.DOKUMENTE_TEXT'"></p>
        <table>
          <thead>
            <tr>
              <th [translate]="'STAMMDATEN.DOCUMENT_TITLE'"></th>
              <!-- <th [translate]="'STAMMDATEN.FILENAME'"></th> -->
              <th [translate]="'STAMMDATEN.ACTION'"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let document of documents">
              <td style="width: 23rem">{{ document.title }}</td>
              <!-- <td style="width: 10rem">
                <ng-container *ngIf="jurPerson">
                  <ng-container
                    *ngIf="jurPerson.Dokumente[document.title]?.Base64"
                  >
                    {{
                      jurPerson.Dokumente[document.title]?.FileName ||
                        "Dokument liegt vor"
                    }}
                  </ng-container></ng-container
                >
                <ng-container *ngIf="jurPerson">
                  <ng-container
                    *ngIf="!jurPerson.Dokumente[document.title]?.Base64"
                  >
                    Das Dokument fehlt.
                  </ng-container>
                </ng-container>
              </td> -->
              <td style="width: 6rem">
                <button class="btn-icon">
                  <ng-container *ngIf="jurPerson">
                    <mat-icon class="info-icon" [style.color]="hasFileOrBase64(document.title) ? '#7cd758' : '#ea110d'">
                      {{ hasFileOrBase64(document.title) ? 'check' : 'close' }}
                    </mat-icon>
                  </ng-container>
                </button>
                <button class="btn-icon" style="cursor: pointer" (click)="fileInputs.click()"
                  id="upload-button-{{document.title}}">
                  <mat-icon class="info-icon"
                    [ngStyle]="{'cursor': 'pointer', 'color': hasFileOrBase64(document.title) ? '#7cd758' : '#ea110d'}">
                    publish
                  </mat-icon>
                </button>

                <!-- Keep input hidden but use more accessible ID and ref -->
                <input id="file-input-{{document.title}}" #fileInputs type="file" accept=".pdf" style="display: none"
                  (change)="uploadRechtsDocument(fileInputs.files, document.title)" />
                <button class="btn-icon" style="cursor: pointer" [disabled]="!hasFileOrBase64(document.title)"
                  (click)="handleClick(document.title)">
                  <mat-icon class="info-icon">search</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>
<mat-progress-spinner style="position: absolute" *ngIf="isLoading" mode="indeterminate"></mat-progress-spinner>
<br />

<form class="col-xl-10 confirm-container" [formGroup]="answerFormGroup">
  <div class="row">
    <div class="col-12">
      <h2 appTranslationModal [translate]="'STAMMDATEN.CONFIRM_DOKUMENTE'"></h2>
      <hr />
      <div class="confirm-checkbox">
        <mat-checkbox formControlName="bestätigt_document1" data-cy="checkbox-complete-document">
          <p class="text-bold" appTranslationModal [translate]="'STAMMDATEN.COMPLETE_DOCUMENTS_CONFIRM'"></p>
        </mat-checkbox>
      </div>
    </div>
  </div>
</form>
<br />

<form class="col-xl-10 card-container">
  <div class="row">
    <div class="col-12">
      <div class="row section-container">
        <h3 appTranslationModal [translate]="'STAMMDATEN.DOKUMENTE_HEADER1'"></h3>
        <p appTranslationModal [translate]="'STAMMDATEN.DOKUMENTE_TEXT1'"></p>
        <table>
          <thead>
            <tr>
              <th [translate]="'Vorname(n)/Nachname'"></th>
              <th [translate]="'Geburtsdatum'"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let natPerson of natPersonen; let i = index">
              <td style="width: 15rem"
                *ngIf="natPerson.Person.Wirtschaftlichberechtigt || natPerson.Person.FiktivWirtschaftlichberechtigt">
                {{ natPerson.Person.Nachname + ' ' + natPerson.Person.Vorname }}
              </td>
              <td style="width: 6rem"
                *ngIf="natPerson.Person.Wirtschaftlichberechtigt || natPerson.Person.FiktivWirtschaftlichberechtigt">
                {{ natPerson.Person.Geburtsdatum | date }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>
<br />
<div class="line-wrapper">
  <mat-icon class="info-icon">info</mat-icon>
  <div>
    <span appTranslationModal [translate]="'STAMMDATEN.MARKIERUNG_INFO'"></span>
    <span><mat-icon class="check-icon ckeckIconHint">check</mat-icon></span>
    <span appTranslationModal [translate]="'STAMMDATEN.MARKIERUNG_INFO_END'"></span>
  </div>
</div>
<br />

<div class="col-xl-10 info-container">
  <div class="row">
    <div class="col-12 col-lg-8 line-wrapper">
      <!-- <mat-icon
        class="info-icon"
        style="overflow: inherit !important; color: transparent"
        >info</mat-icon
      > -->
      <div>
        <span appTranslationModal
          [translate]="'Bei abweichenden Daten oder notwendigen Ergänzungen, teilen Sie uns diese bitte hier mit!'"></span>
      </div>
    </div>
    <div class="col-12 col-lg-4 btn-right">
      <button mat-stroked-button color="accent" (click)="openPopUp()">
        {{ 'STAMMDATEN.ABWEICHUNGEN_MELDEN' | translate }}
      </button>
    </div>
  </div>
</div>

<form class="col-xl-10 card-container mb-3" [formGroup]="answerFormGroup" *ngIf="abweichungenGemeldet">
  <div class="d-flex flex-column">
    <h2 appTranslationModal [translate]="'STAMMDATEN.ABWEICHUNGEN_TITLE'"></h2>
    <div class="row">
      <div class="col-lg-6">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label [translate]="'STAMMDATEN.FIRMENNAME'"></mat-label>
          <input matInput formControlName="eingetragenerName" readonly />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label [translate]="'STAMMDATEN.TITEL'"></mat-label>
          <input matInput formControlName="titel" readonly />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label [translate]="'STAMMDATEN.VORNAME'"></mat-label>
          <input matInput formControlName="vorname" readonly />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label [translate]="'STAMMDATEN.NACHNAME'"></mat-label>
          <input matInput formControlName="nachname" readonly />
        </mat-form-field>
      </div>
    </div>
    <div class="col-12">
      <mat-form-field floatLabel="always" class="full-width" appearance="outline">
        <mat-label [translate]="'STAMMDATEN.NACHRICHT'"></mat-label>
        <textarea matInput formControlName="abweichungNachricht" placeholder="Bitte geben Sie Abweichungen ein..."
          rows="4"></textarea>
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-center">
      <div class="text-center p-2 m-1">
        <button type="button" mat-stroked-button (click)="closePopUp()" color="accent">
          {{ 'STAMMDATEN.ABBRECHEN' | translate }}
        </button>
      </div>
      <div class="text-center p-2 m-1">
        <button type="button" mat-stroked-button (click)="abweichungenSenden()" color="accent"
          [disabled]="!answerFormGroup.controls['abweichungNachricht'].valid">
          Senden
        </button>
      </div>
    </div>
  </div>
</form>

<form class="col-xl-10 confirm-container" [formGroup]="answerFormGroup">
  <div class="row">
    <div class="col-12">
      <h2 appTranslationModal [translate]="'Bestätigung der wirtschaftlich Berechtigten abschließen'"></h2>
      <hr />
      <p appTranslationModal
        [translate]="'Bitte überprüfen Sie die Richtigkeit und Akualität der wirtschaftlich Berechtigen'"></p>
      <div class="confirm-checkbox">
        <mat-checkbox formControlName="bestätigt_document" data-cy="checkbox-document-wirtschaftlich-berechtigten">
          <p class="text-bold" appTranslationModal
            [translate]="'Hiermit bestätige ich die Richtigkeit und Vollständigkeit der wirtschaftlich Berechtigten.'">
          </p>
        </mat-checkbox>
      </div>
    </div>
  </div>
</form>