import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';

export const addBearerTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const token = authService.getKeycloakToken();
  const newReq = req.clone({ headers: req.headers.append('Authorization', 'Bearer ' + token) });

  return next(newReq);
};
