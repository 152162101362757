<div class="col-xl-10 card-container">
  <h1 appTranslationModal [translate]="'ABSCHLUSSSEITE.TITLE'"></h1>
  <p style="margin-bottom: 30px" appTranslationModal [translate]="'ABSCHLUSSSEITE.MAIN_TEXT'"></p>
  <span appTranslationModal [translate]="'ABSCHLUSSSEITE.IBAN_TEXT'"></span>
  <span>&nbsp;</span>
  <span>
    <strong style="font-weight: 600">{{ customerIban }}</strong>
  </span>
  <br />
  <br />

  <div>
    <span appTranslationModal [translate]="'ABSCHLUSSSEITE.tagesGeldkontoIban_TEXT'"></span>
    <span>&nbsp;</span>
    <span>
      <strong style="font-weight: 600">{{ tagesGeldkontoIban }}</strong>
    </span>
    <span>&nbsp;</span>
    <span appTranslationModal [translate]="'ABSCHLUSSSEITE.tagesGeldkontoIban1_TEXT'"></span><span>&nbsp;</span>
    <span>{{ berufsGruppe?.guthabenVerzinsung }} p.a. profitieren.</span>
  </div>
</div>
<div class="col-xl-10 buttons-container">
  <div class="row">
    <div class="col-xl-12 py-3">
      <div *ngIf="user.lang === 'odfin'" class="giroCard">
        <img
          *ngIf="user.lang === 'odfin'"
          src="assets/img/girocard-muster.png"
          style="width: 100%; height: 100%; max-width: 27rem"
        />
        <h3 class="name">{{ customerName }}</h3>
        <p class="iban-odfin">{{ customerIban }}</p>
        <p class="valid-odfin">12/26</p>
      </div>
      <div *ngIf="user.lang === 'mcc'" class="giroCard">
        <img src="assets/img/girocard-muster.svg" style="width: 100%; height: 100%; max-width: 27rem" />
        <h3 class="name">{{ customerName }}</h3>
        <p class="iban">{{ customerIban }}</p>
        <p class="valid">12/26</p>
      </div>
    </div>
    <p appTranslationModal [translate]="'ABSCHLUSSSEITE.CARD_BOTTOM_TEXT'"></p>
    <div class="row">
      <div class="py-4">
        <button
          mat-stroked-button
          class="flex-center"
          data-cy="BACK_TO_ONE"
          (click)="redirectDashboardOne()"
          color="primary"
        >
          {{ 'ABSCHLUSSSEITE.BACKTOONE' | translate }}
        </button>
      </div>
      <div *ngIf="kontoWechselSelected">
        <p appTranslationModal [translate]="'ABSCHLUSSSEITE.KONTOWECHSEL_TEXT'"></p>
        <div class="py-4">
          <button mat-raised-button class="flex-center" (click)="openKontoWechsel()" color="primary">
            Kontowechselservice
          </button>
        </div>
      </div>
    </div>
  </div>
  <form
    style="display: none"
    ngNoForm
    id="kwhForm"
    method="post"
    [action]="kwsURL()"
    enctype="multipart/form-data"
    accept-charset="UTF-8"
  >
    <table>
      <tr>
        <td><label>Kundendaten:</label></td>
        <td>
          <input name="kundendaten" id="kundendaten" value="" />
        </td>
      </tr>
      <tr>
        <td>
          <label for="signatur">Signatur (wird automatisch berechnet):</label>
        </td>
        <td>
          <input type="text" name="signatur" id="signatur" value="" />
        </td>
      </tr>
    </table>
    <button mat-strocked-button type="submit" name="Submit" id="formSubmit">KWS submit</button>
  </form>
</div>
